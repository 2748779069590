import request from "@/utils/request";

/**
 * 获取评论列表
 * @param {*} data 
 * @param {String} page || 页码
 * @param {String} pageSize || 页码大小
 * @param {String} articleId || 文章id
 * @param {String} parentCommentId || 父级评论编号
 * @returns 
 */
export const getCommentLists = (data) => {
  return request({
    url: "/api/app/comment/query",
    method: "post",
    data: data,
  });
};


/**
 * 评论
 * @param {*} data 
 * @param {String} parentCommentId || 上级评论编号
 * @param {String} articleId || 文章id
 * @param {String} content || 内容
 * @returns 
 */
export const addComment = (data) => {
  return request({
    url: "/api/app/comment/add",
    method: "post",
    data: data,
  });
};

/**
 * 删除评论
 * 
 */
export const deleteComment = (id) => {
  return request({
    url: "/api/app/comment/delete",
    method: "put",
    data:id
  });
};
