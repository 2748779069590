<template>
    <div>
        <div ref="qrcode"></div>
    </div>
</template>
   
<script>
import QRCode from 'qrcode'

export default {
    name: 'QrCodeComponent',
    props: {
        text: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.generateQRCode()
    },
    methods: {
        generateQRCode() {
            QRCode.toDataURL(this.text, { errorCorrectionLevel: 'H' })
                .then(url => {
                    this.$refs.qrcode.innerHTML = '<img src="' + url + '">'
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }
}
</script>
<style scoped>
</style>